import $formData from "../../mixins/fromDataGetter";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../store/types";
import authService from "../../services/authService";
import userInfoService from "../../services/userInfoService";

const inputComp = () => import("../input/index.vue");

export default {
    name: "login-comp",
    components: {
        inputComp,
    },
    mixins: [$formData],
    props: ["isLogin", "excoinoLogin"],
    data() {
        return {
            show: true,
            validate: {
                isvalid: true,
                message: "شماره موبایل به درستی وارد نشده است",
            },
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            userInfo: types.GET_USER_INFO,
        }),
    },
    mounted() { },
    methods: {
        ...mapActions({
            checkIsAuth: types.ACTION_CHECK_IS_AUTH,
            userInfoAction: types.ACTION_USER_INFO,
            userInfoForRegisterAction: types.ACTION_INFO_FOR_REGISTER,
            accessTokenAction: types.ACTION_ACCESS_TOKEN,
        }),
        submit() {
            this.$bvToast.hide();
            this.loading = true;
            if (this.isLogin == "true") {
                if (this.form.username === undefined || this.form.password === undefined) {
                    this.$bvToast.toast(this.$i18n.t("auth.login.checkEmpty"), {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                    this.loading = false;
                }
                else {
                    authService
                        .login(this.form.username, this.form.password)
                        .then((response) => {
                            localStorage.setItem("accessToken", response.data.token);
                            localStorage.setItem("isAuth", true);
                            this.checkIsAuth();
                        })
                        .then(() => {
                            userInfoService
                                .getUserInfo()
                                .then((response) => {
                                    this.userInfoAction(response.data).then(() => {
                                        let lang = this.$route.params.lang;
                                        if (!this.userInfo.admin) {

                                            localStorage.setItem(
                                                "bitrahAccessToken",
                                                this.userInfo.merchantId
                                            );
                                            this.$router.push(
                                                `/${lang}/panel/user`
                                            );
                                            this.checkIsAuth();
                                        } else {
                                            localStorage.setItem(
                                                "bitrahAdminAccessToken",
                                                this.userInfo.merchantId
                                            );
                                            this.$router.push(
                                                `/${lang}/panel/admin`
                                            );
                                            this.checkIsAuth();
                                        }
                                    });
                                })
                                .catch((error) => {
                                    this.$bvToast.toast(error.response.data.message, {
                                        title: this.$i18n.t("toast.errorTitle"),
                                        noCloseButton: true,
                                        variant: "custom",
                                        bodyClass:
                                            "dangerBackgroundColor dangerFontColor rtl text-right",
                                        headerClass:
                                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                                        solid: false,
                                    });
                                });
                        })
                        .catch((error) => {
                            let errorObject = error.response.data.data
                            // ADD THIS CONDITION BECAUSE THE ERROR OBJECT DIFFERS ON DIFFERENT CODE STATUS  
                            if('errors' in errorObject) {
                                const keys = Object.keys(errorObject.errors);
                                keys.forEach((key) => {
                                    this.$bvToast.toast(
                                        errorObject.errors[key],
                                        {
                                            title: this.$i18n.t("toast.errorTitle"),
                                            noCloseButton: true,
                                            variant: "custom",
                                            bodyClass:
                                                "dangerBackgroundColor dangerFontColor rtl text-right",
                                            headerClass:
                                                "dangerBackgroundColor dangerFontColor  rtl text-right",
                                            solid: false,
                                        }
                                    );
                                });
                            } else {
                                this.$bvToast.toast(
                                    errorObject.message,
                                    {
                                        title: this.$i18n.t("toast.errorTitle"),
                                        noCloseButton: true,
                                        variant: "custom",
                                        bodyClass:
                                            "dangerBackgroundColor dangerFontColor rtl text-right",
                                        headerClass:
                                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                                        solid: false,
                                    }
                                );
                            }
                        })
                        .finally(() => (this.loading = false));
                }
            } else {
                if (this.form.username === undefined || this.form.password === undefined) {
                    this.$bvToast.toast(this.$i18n.t("auth.login.checkEmpty"), {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                    this.loading = false;
                }
                else {
                    // if (this.form.password.length < 8) {
                    //     this.$bvToast.toast("طول رمز عبور نباید از 8 کاراکتر کمتر باشد", {
                    //         title: "خطا",
                    //         noCloseButton: true,
                    //         variant: "custom",
                    //         bodyClass:
                    //             "dangerBackgroundColor dangerFontColor rtl text-right",
                    //         headerClass:
                    //             "dangerBackgroundColor dangerFontColor  rtl text-right",
                    //         solid: false,
                    //     });
                    //     this.loading = false;
                    // }
                    // else {
                    authService
                        .loginForRegister(this.form.username, this.form.password)
                        .then((response) => {
                            localStorage.setItem("accessToken", response.data.token);
                            userInfoService.getMerchantInfo().then(response => {
                                this.userInfoForRegisterAction(response.data);
                                this.$bvModal.hide("modal-login");
                                this.$emit("hideExcoinoLogin")
                            });

                        }).catch((error) => {
                            if (error.response.data.data.errors) {
                                const keys = Object.keys(error.response.data.data.errors);
                                keys.forEach((key) => {
                                    this.$bvToast.toast(
                                        error.response.data.data.errors[key],
                                        {
                                            title: this.$i18n.t("toast.errorTitle"),
                                            noCloseButton: true,
                                            variant: "custom",
                                            bodyClass:
                                                "dangerBackgroundColor dangerFontColor rtl text-right",
                                            headerClass:
                                                "dangerBackgroundColor dangerFontColor  rtl text-right",
                                            solid: false,
                                        }
                                    );
                                });
                            }
                            else {
                                this.$bvToast.toast(error.response.data.message, {
                                    title: this.$i18n.t("toast.errorTitle"),
                                    noCloseButton: true,
                                    variant: "custom",
                                    bodyClass:
                                        "dangerBackgroundColor dangerFontColor rtl text-right",
                                    headerClass:
                                        "dangerBackgroundColor dangerFontColor  rtl text-right",
                                    solid: false,
                                });
                            }
                        })
                        .finally(() => (this.loading = false));
                    // }
                }
            }
        },
    },
};
