import authAxios from "./config/need-auth-axios";

class userService {
    getMerchantInfo() {
        return authAxios.get("/manifest/board/merchantInfo");
    }
    getUserInfo() {
        return authAxios.get("/manifest/board/info");
    }

}

export default new userService();
